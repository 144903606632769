import React from 'react'
import LayoutModal from '../layout-modal/layout-modal'
import Button from '../../atoms/button/button'
import './modal-confirm.scss'

const ModalConfirm = props => {

  const { onClose, title, text, icon, className, type, onConfirm, ...params } = props

  React.useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "initial"
    }
  }, [])

  return (
    <LayoutModal onClose={onClose}>
      <div className={'f-modal-content ' + (className ? className : '')} {...params}>

        <i className="fal fa-times f-close-button" onClick={onClose}></i>

        <i className="fal fa-exclamation-circle f-confirm-icon"></i>

        <h2 className="f-title--purple f-confirm">¿Estás seguro que deseas eliminar esta mascota?</h2>

        <div className="f-modal-confirm-buttons">
          <Button
            className="f-btn-purple f-button-action f-no"
            typeButton="button"
            // type="submit"
            title='NO'
            onClick={onClose}
          />
          <Button
            className="f-primary-purple f-button-action f-yes"
            typeButton="button"
            // type="submit"
            title='SI'
            onClick={onConfirm}
          />
        </div>

      </div>
    </LayoutModal>
  )
}

export default ModalConfirm
