import React, { useState } from 'react';
import CustomInputText from '../../atoms/inputText/InputText'
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AmbosImg from '../../../assets/images/ambos.png'
import PerrroImg from '../../../assets/images/perro.png'
import GatoImg from '../../../assets/images/gato.png'
import { latoRegular, purple, contrastDark } from '../../../styles/variables.scss';
import './pet-form.scss';

const PurpleRadio = withStyles({
  root: {
    color: purple,
    '&$checked': {
      color: purple,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    color: contrastDark,
    fontSize: 16,
    fontFamily: latoRegular,
  },
}));

const PetForm = props => {

  const { register, Controller, control, nameType, valueType, races, raceValue, raceName, namePet, valueName, errors, onDelete, showIconDelete = true, index, isImage = true, isUpdate = false, petId} = props

  const [valueTypeF, setValueTypeF] = useState(valueType)

  const filterCategory = () => {
    let catevogyActive = valueTypeF
    let result;

    if (catevogyActive === 'PERRO') {
      result = races.filter((race) => race.animal_id === 2)
    } else {
      result = races.filter((race) => race.animal_id === 1)
    }
    return (result && result.length > 0) ? result : races

  }

  const [racesFiltered, setRacesFiltered] = useState(filterCategory())

  const classes = useStyles();

  const handleChagneType = (event) => {
    isImage && setValueTypeF(event.target.value)
  }

  React.useEffect(() => {
    setRacesFiltered(filterCategory())
  }, [valueTypeF])

  return (
    <div className="f-pet-form">

      {isImage &&
        <figure className="f-pet-img-container">
          <img src={valueTypeF === 'PERRO' ? PerrroImg : valueTypeF === 'GATO' ? GatoImg : AmbosImg} alt="Imagen mascota" />
        </figure>}

      <div className="f-pet-form-content">

        <div className="f-pet-header">
          <div className="f-pet-header-title-container">
            <h4 className="f-pet-header-title">{'Mascota' + (index ? index : '')}</h4>
            {showIconDelete && <i className="fal fa-trash-alt f-trash" onClick={onDelete}></i>}
          </div>

          <Controller
            as={
              <RadioGroup className={classes.radioGroup + " f-radio-container"} aria-label="tyoe">
                <FormControlLabel
                  onChange={handleChagneType}
                  value="PERRO"
                  checked={valueTypeF === "PERRO"}
                  control={<PurpleRadio required={true} />}
                  label="Perro" />
                <FormControlLabel
                  onChange={handleChagneType}
                  value="GATO"
                  checked={valueTypeF === "GATO"}
                  control={<PurpleRadio required={true}/>}
                  label="Gato" />
              </RadioGroup>
            }
            name={nameType}
            control={control}
            rules={{ required: true }}
            defaultValue={valueType} // make sure to set up defaultValue
          />
        </div>

        <div className="f-row-pet">

          <input type="hidden" name={`pets[${index-1}].petId`} defaultValue={petId} ref={register()} />

          <div className="f-container-input">
            <CustomInputText
              fullWidth={true}
              label="Nombre de tu mascota"
              id={namePet}
              inputProps={{
                name: namePet, maxLength: 100, placeholder: 'Nany', defaultValue: valueName
              }}
              inputRef={register({
                required: 'Este campo es requerido',
                maxLength: 100,
              })}
              error={errors !== {} && errors.pets && errors.pets[index-1] && errors.pets[index -1].name ? true : false}
              errorText={errors !== {} && errors.pets && errors.pets[index-1] && errors.pets[index -1].name ? "Campo inválido" : ''}
            />
          </div>

          <div className="f-container-input">

            <div 
              className={"f-select" + (errors !== {} && errors.pets && errors.pets[index-1] && errors.pets[index -1].breed ? " f-select--error" : '')} 
              onClick={() => filterCategory()}
            >
              <select defaultValue={raceValue ? raceValue : ''} o={raceValue} name={raceName} id={raceName} ref={register({ required: true })}>
                <option disabled value="" >Selecciona una raza</option>
                {racesFiltered && racesFiltered.length > 0 ?
                  racesFiltered.map((race, index) => {
                    return <option key={index} value={race.nombre_raza}>{race.nombre_raza}</option>
                  })
                  :
                  races.map((race, index) => {
                    return <option key={index} value={race.nombre_raza}>{race.nombre_raza}</option>
                  })
                }
              </select>
            </div>

          </div>
        </div>

      </div>

    </div >
  )
}

export default PetForm
