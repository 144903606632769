import React from "react";
import RegisterLayout from "../../shared/components/molecules/register-layout/register-layout";
import useMobie from '../../shared/hooks/useMobile'
import useRegisterInfo from '../../shared/hooks/useRegister'
import FourStepContent from "../../components/organisms/four-step-content/four-step-content";
import SEO from "../../shared/components/atoms/seo/seo"
import Layout from "../../shared/components/organisms/layout/layout"
import '../../shared/styles/index.scss';

const SecondStep = (props) => {

  // send active modal to layout
  const [modalActive, setModalActive] = React.useState(null);
  const isMobile = useMobie();
  const {registerInfo} = useRegisterInfo();

  return (
    <Layout activeMenu={5} shopButton={false} showHeader={false}>
      <SEO
        title={registerInfo.metaTitulo ? registerInfo.metaTitulo : ''}
        description={registerInfo.metaDescripcion ? registerInfo?.metaDescripcion?.metaDescripcion : ''}
        slug={registerInfo.slug ? registerInfo.slug : ''}
        lang="ES"
      />
      <RegisterLayout redirection="registro/" >
        <FourStepContent
          setModalActive={setModalActive}
          isMobile={isMobile}
          title={registerInfo.tituloIntroductorio}
          registerInfo={registerInfo}
        />
      </RegisterLayout>
      {modalActive &&
        modalActive
      }
    </Layout>
  )
}

export default SecondStep
