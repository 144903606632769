import React, { useEffect, useState } from "react"
import Button from "../../../shared/components/atoms/button/button"
import PetForm from "../../../shared/components/molecules/pet-form/pet-form"
import NavigatorSteps from "../../../components/atoms/navigator-steps/navigator-steps"
import homeLink from "../../../shared/utils/siteUrl"
import { sessionStorage } from "../../../shared/utils/storage"
import { useForm, useFieldArray, Controller } from "react-hook-form"
import TengoMascotaImg from "../../../shared/assets/images/tengo-mascota.png"
import NoTengoMascotaImg from "../../../shared/assets/images/no-tengo-mascota.png"
import ModalConfirm from "../../../shared/components/modals/modal-confirm/modal-confirm"
import "./four-step-content.scss"

const FourStepContent = props => {
  const { title, registerInfo, setModalActive, isMobile } = props

  const [defaultData] = useState(sessionStorage.getItem("infoRegister"))

  const [isForm, setIsForm] = useState(
    defaultData && defaultData.pets ? true : false
  )

  const { register, control, handleSubmit, formState, errors } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      pets: defaultData && defaultData.pets ? defaultData.pets : [],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "pets",
  })

  const handleNoPet = (e, redirection) => {
    e.preventDefault()
    let copyInfoRegister = sessionStorage.getItem("infoRegister")
    copyInfoRegister.pets = []
    sessionStorage.setItem("infoRegister", copyInfoRegister)
    redirection
      ? (window.location.href = homeLink + "registro/confirmacion/")
      : setIsForm(false)
  }

  const onSubmit = data => {
    let infoRegister = sessionStorage.getItem("infoRegister")
    infoRegister.pets = data.pets

    let mascotas = JSON.stringify(infoRegister.pets)

    sessionStorage.setItem("infoRegister", infoRegister)

    window.location.href = homeLink + "registro/confirmacion"
  }

  const closeModal = () => setModalActive(null)

  const openConfirmModal = (e, index) => {
    setModalActive(
      <ModalConfirm
        onClose={closeModal}
        onConfirm={e => {
          remove(index)
          closeModal()
          if (fields.length === 1 && index === 0) handleNoPet(e, false)
        }}
      />
    )
  }

  useEffect(() => {
    if (
      !defaultData ||
      !defaultData.email ||
      !defaultData.cellphone ||
      !defaultData.name ||
      !defaultData.lastname ||
      !defaultData.gender ||
      !defaultData.day ||
      !defaultData.month ||
      !defaultData.year ||
      !defaultData.password
    ) {
      window.location.href = homeLink + "registro/"
    }

    if (defaultData.userId) {
      let initialPets = defaultData.pets ? defaultData.pets : []
      sessionStorage.setItem("initialPets", initialPets)
    }

    if (defaultData.pets && defaultData.pets.length < 1) {
      setIsForm(false)
    }
  }, [])

  return (
    <div className="f-first-step-content">
      <h2 className="f-title--purple f-form-register-title">{title}</h2>

      <NavigatorSteps totalSteps={4} activeStep={4} />

      <h2 className="f-title--purple f-register-title">Datos de tu mascota</h2>

      {!isForm ? (
        <div className="f-have-pets-container">
          <figure
            className="f-have-pets-item frist"
            onClick={() => {
              setIsForm(true)
              fields &&
                fields.length <= 0 &&
                fields.push({ name: null, type: null, breed: "" })
            }}
          >
            <img src={TengoMascotaImg} alt="Tengo mascota" />
          </figure>

          <a
            href={homeLink + "registro/confirmacion"}
            className="f-have-pets-item"
          >
            <img
              src={NoTengoMascotaImg}
              alt="No tengo mascota"
              onClick={e => {
                handleNoPet(e, true)
              }}
            />
          </a>

          <div className="f-buttons-container">
            <Button
              className="f-btn-purple f-button-four-prev"
              title="Atrás"
              href="registro/paso-3"
            />
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <ul className="f-repeatable-form">
            {fields.map((pet, index) => {
              return (
                <li key={index} className="f-repeatable-form-item">
                  <PetForm
                    register={register}
                    Controller={Controller}
                    control={control}
                    namePet={`pets[${index}].name`}
                    valueName={pet.name ? pet.name : ""}
                    nameType={`pets[${index}].type`}
                    valueType={pet.type ? pet.type.toUpperCase() : ""}
                    races={registerInfo.razas}
                    raceValue={pet.breed ? pet.breed : ""}
                    raceName={`pets[${index}].breed`}
                    errors={errors}
                    onDelete={e => {
                      openConfirmModal(e, index)
                    }}
                    showIconDelete={true}
                    index={index + 1}
                    petId={pet?.petId}
                  />
                </li>
              )
            })}

            {formState.isValid && (
              <li className="f-append-pet">
                <p
                  onClick={() => {
                    append({ breed: "", name: "", type: "" })
                  }}
                  className="f-append-button"
                >
                  Agregar otra mascota
                  <i className="fal fa-plus-circle"></i>
                </p>
              </li>
            )}

            <li className="f-repeatable-form-item">
              <div className="f-buttons-container">
                <Button
                  className="f-btn-purple f-button-prev"
                  typeButton="button"
                  title="Atrás"
                  href=""
                  onClick={() => setIsForm(false)}
                />

                <Button
                  className="f-primary-purple f-button-next"
                  typeButton="button"
                  type="submit"
                  title="Siguiente"
                  disabled={!formState.isValid}
                />
              </div>
            </li>
          </ul>
        </form>
      )}
    </div>
  )
}

export default FourStepContent
